import { LOGIN_USER, LOGOUT_USER } from './authUserType';

//helper function

export const loginSuccess = (res) => ({
  type: LOGIN_USER,
  payload: res,
});

export const logoutUserSuccess = () => ({
  type: LOGOUT_USER,
});
