import { LOGIN_USER, LOGOUT_USER } from './authUserType';

const INITIAL_STATE = {
  isLoggedIn: false,
};
export const authUserReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_USER:
      return {
        ...state,
        isLoggedIn: payload?.isLoggedIn,
      };
    case LOGOUT_USER:
      return {
        ...INITIAL_STATE,
        isLoggedIn: false,
      };
    default:
      return state || INITIAL_STATE;
  }
};
