import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React, { lazy, useEffect, startTransition } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes as Switch } from 'react-router-dom';
import App from './App';
import './index.css';

import { BrowserRouter as Router } from 'react-router-dom';

const HealthFrame = lazy(() => import('./pages/HealthFrame/HealthFrame'));
// const HelpAndSupport = lazy(() => import('./pages/help/HelpPage'));
const HomePage = lazy(() => import('./pages/home/HomePage'));
const MyChart = lazy(() => import('./pages/mychart/MyChart'));
const AdminHome = lazy(() => import('./pages/adminHome/AdminHome'));
const Login = lazy(() => import('./pages/login/LoginPage'));

export const RouteScreen = () => {
  const { isLoggedIn } = useSelector((state) => state.auth);
  let user = localStorage.getItem('loggedIn');

  //console.log('Route file is not cached by server');
  // useEffect(() => {
  //   let version = localStorage.getItem('version');
  //   console.log('version', version);
  //   if (version != packageJson.version) {
  //     if ('caches' in window) {
  //       caches.keys().then((names) => {
  //         // Delete all the cache files
  //         names.forEach((name) => {
  //           caches.delete(name);
  //         });
  //       });
  //     }
  //     localStorage.setItem('version', packageJson.version);
  //     // Makes sure the page reloads. Changes are only visible after you refresh.
  //     window.location.reload(true);
  //   }
  // }, []);

  return (
    <Router>
      <Switch>
        <Route exact path='/health-frame' element={<HealthFrame />} />
        {/* <Route exact path='/help-and-support' element={<HelpAndSupport />} /> */}
        <Route exact path={'/home'} element={<HomePage />} />
        <Route exact path='/' element={<App />} />
        {(isLoggedIn || user !== null) && (
          <>
            <Route exact path='/dashboard' element={<MyChart />} />
            <Route exact path='/admin-home' element={<AdminHome />} />
          </>
        )}
        {!isLoggedIn && (
          <>
            <Route exact path={'/admin'} element={<Login />} />
          </>
        )}
        <Route path='*' element={<Navigate to='/' replace />} />
      </Switch>
    </Router>
  );
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
