import React, { useEffect } from "react";
import { Outlet, useNavigate, Navigate } from "react-router-dom";
function App() {
  const navigate = useNavigate();

  useEffect(() => {
    let user = localStorage.getItem("loggedIn");
    if (user !== null) {
      navigate("/dashboard", { replace: true });
    } else {
      navigate("/home", { replace: true });
    }
  }, []);

  return <Outlet />;
}

export default App;
