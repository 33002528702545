import React from 'react';
import { CustomLoader } from '../../pages/help/Loader';

export const Loader = () => {
  return (
    <div style={{ marginTop: '50%' }}>
      <CustomLoader />
    </div>
  );
};
